<template>
  <section>
    <section class="ConOfproductsHead">
      <div class="productsHead"></div>
      <div class="conOfprodHeadTxt">
        Move can help temporarily relieve your pain due to back pain, arthritis,
        shoulder pain, wrist pain, ankle pain, knee pain, muscle soreness,
        sprains and strains, aching joints, and bruises
        <div class="mt-5">
          - Move On Cream & Spray Is Used For Symptomatic Relief From Joint
          Pain, Back Pain,
        </div>
        <div class="mt-2">
          - Safely to use as it is Purely made from essential natural oils
        </div>
      </div>
    </section>
    <section class="ma-5 how-sec d-none">
      <v-container class="h-100">
        <v-row class="h-100 ma-0">
          <v-col cols="12" class="h-md-100">
            <div class="conOfheadTxt w-100 d-flex justify-center">
              <div class="titleTxt my-4">How you use move on ?</div>
            </div>
            <div class="d-flex align-center mt-4 conOfVideo">
              <video
                ref="videoRef"
                src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                id="video-container"
                width="60%"
                controls
              ></video>

              <v-card class="video-card">
                <v-card-title class="video-card-title">
                  Product information
                </v-card-title>
                <v-card-text class="video-card-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap ...
                </v-card-text>

                <router-link to="/" class="readYellow ml-3"
                  >Read More <span>&#8594;</span></router-link
                >
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="productsSec">
      <v-container>
        <v-row class="ma-0">
          <v-col md="12">
            <div>
              <v-btn
                :class="
                  CurrentTab == 'Sprays' ? 'readBtn defaultBtn mr-2' : 'tab'
                "
                @click="CurrentTab = 'Sprays'"
              >
                Sprays
              </v-btn>
              <v-btn
                :class="
                  CurrentTab == 'cream' ? 'readBtn defaultBtn mr-2' : 'tab'
                "
                @click="CurrentTab = 'cream'"
              >
                Cream
              </v-btn>
              <v-btn
                :class="
                  CurrentTab == 'patches' ? 'readBtn defaultBtn mr-2' : 'tab'
                "
                @click="CurrentTab = 'patches'"
              >
                Patches
              </v-btn>
            </div>
          </v-col>
          <v-col md="12" v-if="CurrentTab == 'Sprays'">
            <VueSlickCarousel :arrows="true" v-bind="settings">
              <div
                class="ma-4 conOfProduct"
                :key="index"
                v-for="(item, index) in sprays"
              >
                <div class="d-flex align-center justify-center conOfslideImg">
                  <img :src="item.img" class="slideImg" />
                </div>
                <div class="slideTxt">{{ item.title }}</div>
                <div class="slideSubTxt">{{ item.dec }}</div>
                <router-link to="/about" class="readYellow"
                  >Read More <span>&#8594;</span></router-link
                >
              </div>
            </VueSlickCarousel>
          </v-col>
          <v-col md="12" v-if="CurrentTab == 'cream'">
            <VueSlickCarousel :arrows="true" v-bind="settings">
              <div
                class="ma-4 conOfProduct"
                :key="index"
                v-for="(item, index) in creams"
              >
                <div class="d-flex align-center justify-center conOfslideImg">
                  <img :src="item.img" class="" />
                </div>
                <div class="slideTxt">{{ item.title }}</div>
                <div class="slideSubTxt">{{ item.dec }}</div>
                <router-link to="/about" class="readYellow"
                  >Read More <span>&#8594;</span></router-link
                >
              </div>
            </VueSlickCarousel>
          </v-col>
          <v-col md="12" v-if="CurrentTab == 'patches'">
            <VueSlickCarousel :arrows="true" v-bind="settings">
              <div
                class="ma-4 conOfProduct"
                :key="index"
                v-for="(item, index) in patches"
              >
                <div class="d-flex align-center justify-center conOfslideImg">
                  <img :src="item.img" class="slideImg" />
                </div>
                <div class="slideTxt">{{ item.title }}</div>
                <div class="slideSubTxt">{{ item.dec }}</div>
                <router-link to="/about" class="readYellow"
                  >Read More <span>&#8594;</span></router-link
                >
              </div>
            </VueSlickCarousel>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="review-sec">
      <v-container class="h-100">
        <v-row class="h-100 ma-0">
          <v-col md="4" cols="12">
            <div class="conOfheadTxtRev">
              <div class="titleTxtpurple my-4">Review Our Customer</div>

              <div class="conOfline d-flex jusify-center">
                <span class="thinLine"></span>
                <span class="thickLine"></span>
              </div>
              <div class="subtitle-rev my-4">Rating Distribution</div>
              <router-link to="/" class="seeAll-rev"
                >Can You See All Reviews</router-link
              >
            </div>
          </v-col>
          <v-col cols="12">
            <img src="@/assets/img/review-min.png" class="reviewImg" />
            <VueSlickCarousel :arrows="false" v-bind="settingsReviews">
              <v-card
                class="my-2 w-75 pa-4 review-card"
                :key="index"
                v-for="(item, index) in reviews"
              >
                <v-card-title class="review-card-title">
                  <div>{{ item.title }}</div>
                  <span class="float-left pr-3 stars">★★★★★</span>
                </v-card-title>
                <v-card-text class="review-card-text w-75">
                  {{ item.dec }}
                </v-card-text>
              </v-card>
            </VueSlickCarousel>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "Home",
  components: {
    VueSlickCarousel,
  },

  data() {
    return {
      CurrentTab: "Sprays",
      settings: {
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      settingsReviews: {
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        touchThreshold: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      videoOptions: {
        autoplay: false,
        muted: false,
        controls: true,
        preload: "auto",
        sources: [
          {
            src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
            type: "video/mp4",
          },
        ],
      },
      creams: [
        {
          img: require("@/assets/img/Move On Cream-min.png"),
          title: "Move On Cream",
          dec: " Cream is for elderlies suffering from chronic pain and muscle ache ",
        },
        {
          img: require("@/assets/img/Move On Freez Gel-min.png"),
          title: "Move On Freeze Gel",
          dec: "Gel for rapid relief with cooling effect targeting young people who are exposed to accidental injuries and muscle aches",
        },
      ],
      reviews: [
        {
          img: require("@/assets/img/Move On Cream-min.png"),
          title: "Mayar Mohsen",
          dec: "The product works to reduce pain and for me, it works very well. I will be buying it again,I highly recommend this product.",
        },

        {
          img: require("@/assets/img/Move On Cream-min.png"),
          title: "Helen",
          dec: "My mother is 72 and she is suffering from joint issues she loves it helps reduce her pain",
        },
        {
          img: require("@/assets/img/Move On Cream-min.png"),
          title: "Ahmed said",
          dec: "Best pain relief in the market. Spray on pain & then keep the place tied for sometimes. Works well, you can use it for many types of pains.",
        },
      ],
      sprays: [
        {
          img: require("@/assets/img/Move on freeze spray-min.png"),
          title: "Move On Freeze Spray",
          dec: "Freeze spray for athletes, fitness enthusiasts, and sporty people who require cooling effect to relieve muscle spasm/ cramps and aches, easy to carry and apply within seconds , provides a highly pressurized force of cooling menthol that quickly numbs the pain, low temperatures immediately relieve swelling and pain",
        },
        {
          img: require("@/assets/img/Move on Spray-min.png"),
          title: "Move On Spray",
          dec: "Spray for easy application for those who do not tolerate or do not like rubbing cream, it also provides instant relief, Sprays can be excellent for hard-to-reach spots like your back.",
        },
      ],
      patches: [
        {
          img: require("@/assets/img/Move On Freeze Patch-min.png"),
          title: "Move On Freeze Patch",
          dec: "Relieves minor pain associated with Muscular pain,Back pain,Joint pain,Backache,Bruise and Sprain",
        },
        {
          img: require("@/assets/img/Move On Hot Patch-min.png"),
          title: "Move On Hot Patch",
          dec: "Relieves minor pain associated with Muscular aches ,Back pain,Arthritis,Strains,Bruises and Sprains",
        },
      ],
    };
  },

  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
@import "./products";
</style>
